import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { MemoizePipe } from './utils/memoize.pipe';
import { SanitizeUrlPipe } from './utils/sanitize-url.pipe';
import { LessonCardComponent } from './components/lesson-card/lesson-card.component';
import { TimePipe } from './utils/time.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { LoadingDirective } from './utils/loading.directive';
import {MatIconModule} from '@angular/material/icon';
import { PackageCardComponent } from './components/package-card/package-card.component';



@NgModule({
    declarations: [
        CourseCardComponent,
        MemoizePipe,
        SanitizeUrlPipe,
        LessonCardComponent,
        TimePipe,
        ProgressBarComponent,
        LoadingButtonComponent,
        LoadingDirective,
        PackageCardComponent
    ],
    exports: [
        MemoizePipe,
        CourseCardComponent,
        SanitizeUrlPipe,
        LessonCardComponent,
        TimePipe,
        ProgressBarComponent,
        LoadingDirective,
        PackageCardComponent
    ],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class SharedModule { }
