import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {catchError, map, Observable, of} from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url === '/') {
      this.router.navigate(['/dashboard']);
      return  false
    } else {
      if (this.userService.curUser) {
        if (state.url.includes('admin')) {
          return this.userService.curUser.role === 'ADMIN' || this.userService.curUser.role === 'SUPER_ADMIN';
        } else {
          return true;
        }
      } else {
        return this.userService.getUser$().pipe(catchError((err) => of(false)), map((resp) => {
          if (!resp) {
            this.router.navigate(['/login']);
            return false
          } else {
            if (state.url.includes('admin')) {
              return this.userService.curUser!.role === 'ADMIN' || this.userService.curUser!.role === 'SUPER_ADMIN';
            } else {
              return true;
            }
          }
        }));
      }
    }
  }
}
