import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class AppInterceptorInterceptor implements HttpInterceptor {

  constructor(private userService: UserService, private router: Router, private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const moddedReq = request.clone(
      {
        headers: request.headers
          .set('Content-Type', 'application/json')
          .set('Accept', "*/*")
          .set('Access-Control-Allow-Origin', "*")
          .set('X-Requested-With', 'XMLHttpRequest')
          .set('X-XSRF-TOKEN', this.userService.token ?? ''),
          withCredentials: true
          // .set('Accept-Encoding', 'gzip, deflate, br')
          // .set('Connection', 'keep-alive')
      }
    )

    return next.handle(moddedReq).pipe(
      catchError((err) => {
        if (err?.status === 403) {
          this.userService.setCurUser(null);
          this.router.navigate(['/login']);
          this.spinner.hide()
        }
        return throwError(err);
      })
    );
  }
}
