import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {Router, RouterModule, RouterOutlet, Routes} from '@angular/router';
import {AuthGuard} from "./shared/utils/auth.guard";
import {NgxSpinnerModule} from "ngx-spinner";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppInterceptorInterceptor} from './shared/utils/app-interceptor.interceptor';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyTableModule} from '@angular/material/legacy-table';
import {MatLegacySnackBarModule} from '@angular/material/legacy-snack-bar';
import {SharedModule} from './shared/shared.module';
import * as Sentry from "@sentry/angular-ivy";
import {DashboardLandingStateService} from './shared/services/dashboard-landing-state.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./dashboard-landing/dashboard-landing.module').then(m => m.DashboardLandingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'course',
    loadChildren: () => import('./lesson/lesson.module').then(m => m.LessonModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'info',
    loadChildren: () => import('./dezphonics-extras/dezphonics-extras.module').then(m => m.DezphonicsExtrasModule),
  },
  {
    path: 'purchase',
    loadChildren: () => import('./purchases/purchases.module').then(m => m.PurchasesModule),
    canActivate: [AuthGuard],
  }
]

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatLegacyDialogModule,
    MatLegacyTableModule,
    MatLegacySnackBarModule,
    SharedModule,
    // NgxStripeModule.forRoot('pk_test_TYooMQauvdEDq54NiTphI7jx'),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})
  ],
  providers: [
    DashboardLandingStateService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],  bootstrap: [AppComponent],
})
export class AppModule {
}
