import {Component, OnInit} from '@angular/core';
import {UserService} from "./shared/services/user.service";
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from "./shared/models/user";
import {environment} from '../environments/environment';
import {Capacitor} from '@capacitor/core';
import {App} from '@capacitor/app';
import {animate, style, transition, trigger} from '@angular/animations';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ErrorMessageService} from './shared/services/error-message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: '0%'}),
        animate('200ms ease-in', style({opacity: '100%'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({opacity: '0%'}))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {

  ngOnInit() {
    if (Capacitor.getPlatform() === 'android') this.registerAndroidListener();
  }

  registerAndroidListener() {
    App.addListener('backButton', (data) => {
      if (data.canGoBack) window.history.back();
      else App.exitApp();
    });
  }

  constructor(
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private error: ErrorMessageService
  ) {
  }

  get curUser$(): Observable<User | null> {
    return this.userService.curUser$;
  }

  logoutUrl = `${environment.apiUrl}/logout`

  title = 'DezPhonics';

  // logout() {
  //   this.userService.logout();
  // }
  mobileSideNav$ = new BehaviorSubject<boolean>(false);
  toggleSideNav() {
    this.mobileSideNav$.next(!this.mobileSideNav$.value);
  }

  logout() {
    this.http.post<string>(`${environment.apiUrl}/api/v1/auth/manual-logout`, null).subscribe(() => {
      this.userService.setCurUser(null);
      if (this.mobileSideNav$.value) {
        this.mobileSideNav$.next(false);
      }
      this.router.navigate(['/login']);
    }, (err) => this.error.displayErrorMessage(err))
    // window.open(this.logoutUrl);
  }
}
