import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, finalize, Observable, of, switchMap, tap} from 'rxjs';
import {User} from "../models/user";
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ErrorMessageService} from './error-message.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _curUser$ = new BehaviorSubject<User | null>(null);
  curUser$ = this._curUser$.asObservable();
  token: string | null = null;

  constructor(
    private http: HttpClient,
    private error: ErrorMessageService,
    private router: Router
    ) {

  }

  get curUser(): User | null {
    return this._curUser$.value;
  }

  setCurUser(user: User | null) {
    this._curUser$.next(user);
  }

  getUser$(): Observable<User | null>  {
    return this.http.get<{token: string} | null>(`${environment.apiUrl}/api/token`).pipe(
      switchMap((resp) => {
        if (resp) {
          this.token = resp.token;
          return this.http.get<User | null>(`${environment.apiUrl}/api/v1/user/get-user`).pipe(
            catchError((err) => {
              this.error.displayErrorMessage(err)
              return of(null)
            }),
            tap((resp) => this.setCurUser(resp)));
        } else {
          return of(null)
        }
      })
    )
  }

  changePassword$(req: {oldPassword: string, newPassword: string}): Observable<{responseMessage: string} | null> {
    return this.http.post<{responseMessage: string}>(`${environment.apiUrl}/api/v1/user/change-password`, req).pipe(
      catchError((err) => {
        this.error.displayErrorMessage(err)
        return of(null)
      })
    );
  }

  deleteAccount() {
    this.http.post(`${environment.apiUrl}/api/v1/user/delete`, null).subscribe(() => {
      this.setCurUser(null);
      this.router.navigate(['/login']);
    })
  }
}
