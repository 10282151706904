import {Injectable} from '@angular/core';
import {BehaviorSubject, delay} from 'rxjs';
import {CourseDTO, DashboardDTO, LessonDTO} from '../models/course-models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ErrorMessageService} from './error-message.service';
import {Router} from '@angular/router';

@Injectable()
export class DashboardLandingStateService {
  private _dashboardState$ = new BehaviorSubject<DashboardDTO | null>(null);
  dashboardState$ = this._dashboardState$.asObservable();

  get courses(): CourseDTO[] | undefined {
    return this._dashboardState$.value?.courses;
  }

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private error: ErrorMessageService,
    private router: Router
  ) {
  }

  fetchDashboardState() {
    this.loading$.next(true);
    this.http.get<DashboardDTO>(`${environment.apiUrl}/api/v1/dashboard/fetch-dashboard`).subscribe(resp => {
        this.loading$.next(false);
        this._dashboardState$.next(resp);
      }, (err) => {
        this.loading$.next(false)
        this.error.displayErrorMessage(err)
      }
    );
  }

  packagePurchased(packageId: number, paymentIntent: string) {
    this.loading$.next(true);
    this.http.post<{status: string}>(`${environment.apiUrl}/api/v1/purchases/purchase-package`, {
      packageId,
      paymentIntent
    }).subscribe((resp) => {
      this.loading$.next(false);
      switch (resp.status) {
        case 'SUCCESS':
          this.fetchDashboardState();
          break;
        case 'PAYMENT_PROCESSED':
          this.router.navigate(['/dashboard']);
          break;
        case 'PACKAGE_OWNED':
          this.router.navigate(['/dashboard']);
          break;
      }
    }, (err) => {
      this.loading$.next(false);
      this.error.displayErrorMessage(err)
    });
  }
}
