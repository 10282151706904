<div class="w-100 position-fixed top-0 left-0 z-2 top-nav">
  <div class="container-fluid d-flex flex-row align-items-center justify-content-between py-3">
    <h3  routerLink="/dashboard" style="cursor: pointer" class="mb-0 text-white">DezPhonics</h3>

    <div class="dropdown profile-dropdown-wrapper">
      <a href="#" id="accountDropDown" role="button" data-bs-toggle="dropdown" aria-expanded="false" class="profile-dropdown d-flex flex-row gap-1 m-d-none align-items-center" *ngIf="curUser$ | async as user">
        <div class="profile-icon me-1">
          <span class="material-symbols-rounded">person</span>
        </div>

        <p class="mb-0 fw-bold text-white">{{user.firstName}}</p>

        <span class="text-white material-symbols-rounded">expand_more</span>
      </a>

      <ul class="dropdown-menu" aria-labelledby="accountDropDown">
        <li><a routerLink="/profile" class="dropdown-item" href="#">Profile</a></li>
<!--        <li *ngIf="(curUser$ | async)?.role === 'ADMIN'"><a routerLink="/admin" class="dropdown-item" href="#">Admin</a></li>-->
<!--        <li><a class="dropdown-item text-danger" [href]="logoutUrl">Logout</a></li>-->
        <li><a class="dropdown-item text-danger" (click)="logout()">Logout</a></li>
      </ul>
    </div>

      <div *ngIf="curUser$ | async" class="mobile-nav-btn">
        <span (click)="toggleSideNav()" class="material-symbols-rounded mobile-menu">menu</span>
      </div>
  </div>
</div>

<div class="h-100">
  <div *ngIf="curUser$ | async as user" class="sidenav-content">
    <div style="padding-top: 65px;">
      <div class="px-3 py-2 mt-2">
        <button routerLinkActive="active-link" routerLink="/dashboard" class="py-2 px-3 btn btn-nav">
          <span class="material-symbols-rounded">home</span> Dashboard
        </button>
      </div>

      <div class="px-3 py-2">
        <button routerLinkActive="active-link" routerLink="/learning-module" class="py-2 px-3 btn btn-nav">
          <span class="material-symbols-rounded">view_cozy</span> Courses
        </button>
      </div>

      <div *ngIf="user.availablePackages && user.availablePackages.length > 0" class="px-3 py-2">
        <button routerLinkActive="active-link" routerLink="/info/sight-words-list" class="py-2 px-3 btn btn-nav">
          <span class="material-symbols-rounded">list</span> Sight Words List
        </button>
      </div>

      <div *ngIf="user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'" class="px-3 py-2">
        <button routerLinkActive="active-link" routerLink="/admin" class="py-2 px-3 btn btn-nav">
          <span class="material-symbols-rounded">admin_panel_settings</span> Admin
        </button>
      </div>
    </div>
  </div>

    <div (click)="mobileSideNav$.next(false)" [@fadeInOut] *ngIf="mobileSideNav$ | async" class="backdrop"></div>

    <div [@slideInOut] *ngIf="mobileSideNav$ | async" class="mobile-sidenav-content">
      <div *ngIf="curUser$ | async as user" style="padding-top: 65px;">
        <div class="px-3 py-2 mt-2">
          <button (click)="mobileSideNav$.next(false)" routerLinkActive="active-link" routerLink="/dashboard" class="py-2 px-3 btn btn-nav">
            <span class="material-symbols-rounded">home</span> Dashboard
          </button>
        </div>
        <div class="px-3 py-2">
          <button  (click)="mobileSideNav$.next(false)"  routerLinkActive="active-link" routerLink="/learning-module" class="py-2 px-3 btn btn-nav">
            <span class="material-symbols-rounded">view_cozy</span> Courses
          </button>
        </div>
        <div *ngIf="user?.availablePackages && user.availablePackages.length > 0" class="px-3 py-2">
          <button routerLinkActive="active-link" routerLink="/info/sight-words-list" class="py-2 px-3 btn btn-nav">
            <span class="material-symbols-rounded">list</span> Sight Words List
          </button>
        </div>
        <div class="px-3 py-2">
          <button  (click)="mobileSideNav$.next(false)"  routerLinkActive="active-link" routerLink="/profile" class="py-2 px-3 btn btn-nav">
            <span class="material-symbols-rounded">person</span> Profile
          </button>
        </div>
        <div *ngIf="user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'" class="px-3 py-2">
          <button routerLinkActive="active-link" routerLink="/admin" class="py-2 px-3 btn btn-nav">
            <span class="material-symbols-rounded">admin_panel_settings</span> Admin
          </button>
        </div>
      </div>

      <button (click)="logout()" class="w-100 btn-danger p-3 logout-btn">
        Logout
      </button>
    </div>
  <div class="ios-wrapper">
  <div [class.main-content]="curUser$ | async">
    <div class="w-100">
      <router-outlet></router-outlet>
    </div>
  </div>
  </div>
</div>

<ngx-spinner type="ball-clip-rotate"></ngx-spinner>
